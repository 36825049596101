import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Alert, Spinner, AlertActionCloseButton, TextArea } from '@patternfly/react-core';

const Emulators = () => {
    const { register, handleSubmit } = useForm();
    const [emulators, setEmulators] = useState([]);
    const [flashFiles, setFlashFiles] = useState([]);
    const [loadingEmulators, setLoadingEmulators] = useState(false);
    const [loadingFlashFiles, setLoadingFlashFile] = useState(false);
    const [loadingCommand, setLoadingCommand] = useState(false);
    const [commandOutput, setCommandOutput] = useState();
    const [commandOutputVisible, setCommandOutputVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setLoadingEmulators(true);
        console.log('Fetching data...')
        fetch('/iot/')
            .then(res => res.json())
            .then((data) => {
                setEmulators(data);
                setLoadingEmulators(false);
            })
            .catch(console.log);

        setLoadingFlashFile(true);
        fetch('/iot/emulator-flash-files')
            .then(res => res.json())
            .then((data) => {
                setFlashFiles(data);
                setLoadingFlashFile(false);
            })
            .catch(console.log);
    };

    const onSubmit = (data) => {
        setLoadingCommand(true);
        console.log("Turn device on", data);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ hardwareId: data.hardwareId })
        };
        fetch('/iot/on', requestOptions)
            .then(res => {
                setLoadingCommand(false);
                return res.json()
            })
            .then(res => fetchData())
            .catch(console.log)
    };

    const isEmulatorActiveForFile = (flashFile) => {
        for (const emulator of emulators) {
            if (emulator.flashFile === flashFile) {
                return true;
            }
        }
        return false;
    }

    const deleteEmulatorFile = (data) => {
        setLoadingCommand(true);
        console.log("Delete flashFile", data);
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ hardwareId: data.hardwareId })
        };
        fetch('/iot/emulator-flash-files/?fileName=' + encodeURIComponent(data), requestOptions)
            .then(res => {
                setLoadingCommand(false);
                return res.json()
            })
            .then(res => fetchData())
            .catch(console.log)
    };

    const turnEmulatorOff = (data) => {
        console.log("Turn emulator off", data);
        postCommand('/iot/' + data.hardwareId + '/off', JSON.stringify({ hardwareId: data.hardwareId }));
    };

    const cmdQRCode = (data) => {
        const qrcodeContent = prompt("Conteúdo QRCode a ser enviado");
        if (qrcodeContent) {
        console.log('cmdQRCode', data, qrcodeContent);
        postCommand('/iot/' + data.hardwareId + '/qrcode', qrcodeContent);
        }
    };

    const cmdNFC = (data) => {
        const nfcContent = prompt("Conteúdo NFC a ser enviado");
        if (nfcContent) {
        console.log('cmdNFC', data, nfcContent);
        postCommand('/iot/' + data.hardwareId + '/nfc', nfcContent);
        }
    };

    const cmdGoOnline = (data) => {
        console.log('cmdGoOnline', data);
        postCommand('/iot/' + data.hardwareId + '/go-online')
    };

    const cmdGoOffline = (data) => {
        console.log('cmdGoOffline', data);
        postCommand('/iot/' + data.hardwareId + '/go-offline')
    };

    const cmdTurnstileTurn = (data) => {
        console.log('cmdTurnstileTurn', data);
        postCommand('/iot/' + data.hardwareId + '/turnstileTurn')
    };

    const cmdTurnstileTimeout = (data) => {
        console.log('cmdTurnstileTimeout', data);
        postCommand('/iot/' + data.hardwareId + '/turnstileTimeout')
    };

    const cmdExternalReleaseButtonPush = (data) => {
        console.log('cmdExternalReleaseButtonPush', data);
        postCommand('/iot/' + data.hardwareId + '/external-release-button-push')
    };

    const cmdInput = (data, input) => {
        console.log('cmdInput', input, data);
        postCommand('/iot/' + data.hardwareId + '/input/' + input)
    };
    
    const cmdRaw = (data) => {
        const command = prompt("Comando a ser enviado");
        if (command) {
        console.log('cmdRaw', data, command);
        postCommand('/iot/' + data.hardwareId + '/command', command);
        }
    };

    const postCommand = (url, body) => {
        setLoadingCommand(true);
        console.log('postCommand', url, body);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: body
        };
        fetch(url, requestOptions)
            .then(res => {
                setLoadingCommand(false);
                if (res.ok) {
                    return res.json();
                } else {
                    setCommandOutputVisible(true);
                    setCommandOutput({'command': url, 'output': [res.status + " " + res.statusText]});    
                    return Promise.reject(res.status + " " + res.statusText);
                }
            })
            .then(res => {
                console.log('responsecontent', res);
                setCommandOutputVisible(true);
                if (Array.isArray(res)) {
                    let resJoined = {'command': '', 'output': []};
                    res.map(r => {
                        resJoined.command += r.command + '; ';
                        resJoined.output.push(r.output);
                        // console.log('responsecontent array', r);
                        // setCommandOutput(r);
                        return r;
                    });
                    setCommandOutput(resJoined);
                } else {
                    setCommandOutput(res);
                }
                fetchData();
            })
            .catch(e => {
                console.log(e);
                setErrorMessage(e);
            })
    }

    const hideCommandOutput = () => {
        setCommandOutputVisible(false);
    }

    return (
        <div>
            {errorMessage ? <Alert
                variant='danger'
                title={errorMessage}
                ariaLive='assertive'
                aria-atomic='false'
                key='error'
            /> : null
            }
            {(loadingEmulators || loadingFlashFiles || loadingCommand) ? <Alert
                variant='info'
                title='Carregando dados'
                aria-atomic='false'
                key='loading'
            /> : null}
            {commandOutputVisible && (
                <Alert
                    variant="default"
                    title={(commandOutput && commandOutput.command) ? commandOutput.command : commandOutput}
                    action={<AlertActionCloseButton onClose={hideCommandOutput} />}>

                    <TextArea aria-label="command-output" value={(commandOutput && commandOutput.output) ? commandOutput.output.join("\r\n") : ''} />

                </Alert>
            )}
            <form onSubmit={handleSubmit(onSubmit)} >
                <table className="pf-c-table pf-m-grid-md" role="grid" aria-label="Supersonic Subatomic Particles" id="table-basic">
                    <caption>Iniciar nova instância de emulador {(loadingEmulators || loadingFlashFiles || loadingCommand) ? <Spinner isSVG size="md" /> : null}</caption>
                    <thead>
                        <tr role="row">
                            <th role="columnheader" scope="col"><input {...register("hardwareId")} placeholder="Hardware ID" /></th>
                            <th role="columnheader" scope="col"> {(loadingEmulators || loadingFlashFiles || loadingCommand) ? <Spinner isSVG size="md" /> : <input type="submit" value="Iniciar nova instância" />}</th>
                        </tr>
                    </thead>
                </table>
            </form>
            <center><h1>Lista de emuladores ativos</h1></center>
            <table className="pf-c-table pf-m-grid-md" role="grid" aria-label="Lista de emuladores ativos" id="table-basic">
                <caption>Emuladores ativos {loadingEmulators ? <Spinner isSVG size="md" /> : null}</caption>
                <thead>
                    <tr role="row">
                        <th role="columnheader" scope="col">Hardware ID</th>
                        <th role="columnheader" scope="col">Remote server</th>
                        <th role="columnheader" scope="col">Remote port</th>
                        <th role="columnheader" scope="col">Flash file</th>
                        <th role="columnheader" scope="col">Ações</th>
                    </tr>
                </thead>
                {emulators.map((emulator) => (
                    <tbody key={emulator.hardwareId}>
                        <tr role="row" >
                            <td role="cell" data-label="Hardware ID">
                                <a href={"https://qa.iotmonitor.mobipix.com.br/iot-messages?hardwareId=" + parseInt(emulator.hardwareId, 16)} target="_blank" rel="noreferrer">{emulator.hardwareId}</a>
                            </td>
                            <td role="cell" data-label="Remote server">{emulator.remoteServer}</td>
                            <td role="cell" data-label="Remote port">{emulator.remotePort}</td>
                            <td role="cell" data-label="Flash file">{emulator.flashFile}</td>
                            <td role="cell" data-label="Actions">
                                {loadingCommand ? <Spinner isSVG size="xd" /> : (
                                    <div>
                                        Pagamentos: <button onClick={(e) => cmdQRCode(emulator, e)}>Enviar QRCode</button>
                                        <button onClick={(e) => cmdNFC(emulator, e)}>Enviar NFC</button>
                                        <button onClick={(e) => cmdExternalReleaseButtonPush(emulator, e)}>Botoeira</button>
                                        <br/>
                                        Catraca: <button onClick={(e) => cmdTurnstileTurn(emulator, e)}>Giro de catraca</button>
                                        <button onClick={(e) => cmdTurnstileTimeout(emulator, e)}>Timeout de catraca</button>
                                        <br/>

                                        Inputs: <button onClick={(e) => cmdInput(emulator, '1/on', e)}>Input 1 - ON</button>
                                        <button onClick={(e) => cmdInput(emulator, '1/off', e)}>Input 1 - OFF</button>
                                        <button onClick={(e) => cmdInput(emulator, '2/on', e)}>Input 2 - ON</button>
                                        <button onClick={(e) => cmdInput(emulator, '2/off', e)}>Input 2 - OFF</button>
                                        <button onClick={(e) => cmdInput(emulator, '3/on', e)}>Input 3 - ON</button>
                                        <button onClick={(e) => cmdInput(emulator, '3/off', e)}>Input 3 - OFF</button>

                                        <br/>
                                        Comunicação: <button onClick={(e) => cmdGoOnline(emulator, e)}>Forçar online</button>
                                        <button onClick={(e) => cmdGoOffline(emulator, e)}>Forçar offline</button>
                                        <button onClick={(e) => cmdRaw(emulator, e)}>Enviar comando</button>
                                        <br/>
                                        <button onClick={(e) => turnEmulatorOff(emulator, e)}>Desligar</button>
                                        <br/>
                                    </div>
                                )}
                            </td>
                        </tr>
                    </tbody>
                ))}
            </table>
            <br />
            <table className="pf-c-table pf-m-grid-md" role="grid" aria-label="Lista de arquivos de flash" id="table-basic">
                <caption>Arquivos de emuladores existentes{loadingFlashFiles ? <Spinner isSVG size="md" /> : null}</caption>
                <thead>
                    <tr role="row">
                        <th role="columnheader" scope="col">Arquivo</th>
                        <th role="columnheader" scope="col">Ações</th>
                    </tr>
                </thead>
                {flashFiles.map((flashFile) => (
                    <tbody key={flashFile}>
                        <tr role="row">
                            <td role="cell" data-label="Arquivo">{flashFile}</td>
                            {isEmulatorActiveForFile(flashFile) === true ? null :
                                <td role="cell" data-label="Arquivo">
                                    <button onClick={(e) => onSubmit({ hardwareId: flashFile.replaceAll('.flash', '') }, e)}>Ligar</button>&nbsp;|&nbsp;
                                    <button onClick={(e) => deleteEmulatorFile(flashFile, e)}>Excluir</button>
                                </td>
                            }
                        </tr>
                    </tbody>
                ))}
            </table>
        </div>
    )
};

export default Emulators
