import React, {Component} from 'react';
import Emulators from './components/emulators'
import './App.css';

class App extends Component {
  render () {
    return (
      <Emulators />
    );
  }
}

export default App;
